import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InputError, InputLabel } from './TextInput'

const Input = styled.textarea`
  padding: 15px;
  border: 1px solid ${({ hasError }) => (hasError ? 'red' : 'var(--blue-400)')};
  width: 100%;
  max-width: 400px;
  border-radius: 4px;
  display: block;
  box-sizing: border-box;
  font-size: 20px;
  resize: vertical;
  ::placeholder { 
    color: var(--blue-300); 
  }
`;

const TextInput = ({
  label,
  className,
  field: { name, ...field }, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const error = errors[name];
  const touch = touched[name];
  return (
    <div className={className}>
      <InputLabel htmlFor={name} error={error}>{label}</InputLabel>
      <Input
        id={name}
        name={name}
        hasError={error && touch}
        rows="6"
        {...field}
        {...props}
      />
      {touch && error && <InputError>{error}</InputError>}
    </div>
  );
};

TextInput.propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types,react/require-default-props */
  field: PropTypes.object,
  form: PropTypes.object,
  /* eslint-enable react/forbid-prop-types,react/require-default-props */
};

export default styled(TextInput)`
  margin: 5px 0 20px;
  width: 100%;
`;