import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Container from '../components/Container'
import ContactForm from '../components/ContactForm'
import PageHeader from '../components/PageHeader'
import Panel from '../components/Panel'

const Offices = styled.div`
 background-color: var(--blue-100);
 padding: 40px 0;

 ${Container} {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
 }

 ${Panel} {
    margin: 40px 20px;
    padding: 40px;
    flex: 1 1 300px;

    h3 {
      color: var(--blue-500);
      border-bottom: 2px solid var(--red);
      padding-bottom: 10px;
      display: inline-block;
    }
    
    p {
      max-width: 500px;
      font-size: 16px;
    }
  }
`

const ConfirmMessage = styled.div`
  margin: 150px 0;
`

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    }
  }
  render() {
    const { data: { prismicContactPage } } = this.props
    const { data } = prismicContactPage;
    return (
      <Layout>
        <Helmet
          title={`Charthouse | ${data.title.text}`}
          meta={[
            { property: 'og:title', content: `Charthouse | ${data.title.text}` },
            { name: 'twitter:title', content: `Charthouse | ${data.title.text}` },
          ]}
        />
        <PageHeader 
          heading={data.title.text}
          introduction={data.introduction.html}
        />
        <Container>
          {this.state.submitted ? (
            <ConfirmMessage>
              <h2>Thank you for your message</h2>
              <p>We'll be in touch</p>
            </ConfirmMessage>
          ) : <ContactForm handleSubmitted={() => this.setState({ submitted: true })} />}
        </Container>

        <Offices>
          <Container>
            {/* <h2>{data.offices_heading.text}</h2> */}
            {data.offices.map(({ heading, address, phone, link_label, link }) => (
              <Panel key={heading.text}>
                <h3>{heading.text}</h3>
                <div dangerouslySetInnerHTML={{ __html: address.html }} />
                <p>{phone.text}</p>
                {link && <a href={link.url}>{link_label.text}</a>}
              </Panel>
            ))}
          </Container>
        </Offices>

      </Layout>
    )
  }
}

export default styled(ContactPage)`

`

export const pageQuery = graphql`
  query ContactPageQuery {
    prismicContactPage {
      data {
        title {
          text
        }
        introduction {
          html
        }
        offices_heading {
          text
        }
        offices_description {
          html
        }
        offices {
          heading {
            text
          }
          address {
            html
          }
          phone {
            text
          }
          link_label {
            text
          }
          link {
            url
          }
        }
      }
    }
  }
`