import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Field, withFormik } from 'formik';
import * as Yup from 'yup';

import Button from './Button';
import TextInput from './TextInput';
import TextArea from './TextArea';

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class ContactForm extends React.Component {
  render() {
    const {
      className,
      isSubmitting,
      errors,
      dirty,
    } = this.props;
    const hasErrors = Object.keys(errors).length !== 0;
    return (
      <div className={className}>
        <Form netlify="true" data-netlify="true" data-netlify-honeypot="bot-field" name="contact">
          <input type="hidden" name="form-name" value="contact" />
          <Field
            type="text"
            name="name"
            label="Name"
            placeholder=""
            component={TextInput}
          />
          <Field
            type="text"
            name="organisation"
            label="Organisation"
            placeholder=""
            component={TextInput}
          />
          <Field
            type="email"
            name="email"
            label="Email"
            placeholder=""
            component={TextInput}
          />
          <Field
            type="text"
            name="message"
            label="Message"
            placeholder=""
            component={TextArea}
          />
          <Button
            type="submit"
            label="Submit"
            loadingLabel="Submitting"
            loading={isSubmitting}
            disabled={!dirty || hasErrors}
          />
        </Form>
      </div>
    );
  }
}

ContactForm.propTypes = {
  className: PropTypes.string.isRequired,
};

ContactForm.defaultProps = {

};

export default withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string().required('What\'s your name?'),
    email: Yup.string().email('Invalid email address').required('What\'s your email?'),
  }),

  handleSubmit: (values, { setSubmitting, props }) => {
    const body = encode({ 'form-name': 'contact', ...values })
    console.log(body)
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body
    })
      .then(() => props.handleSubmitted())
      .catch(error => alert(error));
    setSubmitting(false);
  }
})(styled(ContactForm)`
  padding: 60px 20px;
  margin-bottom: 80px;
`);